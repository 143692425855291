.article {
  width: 940px;
  height: auto;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  margin: 20px auto;
}

.title {
  color: rgba(24, 144, 255, 1);
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: start;
}

.blockOne {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.overBlock {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.blockRight {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}

.avatar {
  grid-row: 1/3;
  grid-column: 2/3;
}

.blockTwo {
  width: 700px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 22px;
}

.tag {
  width: auto;
  padding: 1px 5px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag:first-child {
  border: 1px solid black;
  color: black;
}

.blockTags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.blockLike {
  display: flex;
  align-items: center;
}

.likeText {
  padding-left: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.name {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
}

.data {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.text {
  margin-top: 10px;
  font-family: 'Inter', sans-serif;
  word-break: break-all;
  line-height: 28px;
}

.buttonLike {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.blockForBut {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.editButton {
  width: 65px;
  height: 31px;
  border-radius: 5px;
  border: 1px solid rgba(82, 196, 26, 1);
  color: rgba(82, 196, 26, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.deleteBTN,
.editButton {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.blockArt {
  display: flex;
  gap: 10px;
}
