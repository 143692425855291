.articles {
  position: relative;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 500px;
  margin: 0 auto;
}
