.article {
  width: 940px;
  height: 140px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  width: 60%;
  margin: 20px auto;
}

.title {
  color: rgba(24, 144, 255, 1);
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-decoration: none;
}

.overBlock {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.blockOne {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 900px;
}

.blockRight {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}

.avatar {
  grid-row: 1/3;
  grid-column: 2/3;
}

.blockTwo {
  margin-top: 10px;
  width: 700px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 22px;
  height: auto;
  word-break: break-all;
}

.tag {
  width: auto;
  padding: 1px 5px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag:first-child {
  border: 1px solid black;
  color: black;
}

.blockLike {
  display: flex;
  align-items: start;
  width: auto;
}

.likeText {
  padding-left: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
}

.name {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
}

.data {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.buttonLike {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.blockTags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
